import React from "react";
import { useRouter } from "next/router";

/**
 * TODO: Fix conditional routing with nextjs version 13
 * */
const Route: React.FC<{
  path: string;
  children: JSX.Element;
  isBaseRoute?: boolean;
  /**
   * resolvedUrl is the nextjs prop which gives access
   * to current browser url in getServerSideProps
   * */
  pageProps?: { resolvedUrl?: string };
}> = ({ path, children, pageProps, isBaseRoute = false }) => {
  const router = useRouter();

  return (
    <>
      {(router.asPath.startsWith(path) &&
        path.slice(1) === router.asPath.split("/")[1]) ||
      pageProps?.resolvedUrl?.startsWith(path) ||
      isBaseRoute ? (
        <>{children}</>
      ) : (
        <></>
      )}
    </>
  );
};

export default Route;
