import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { useIsomorphicLayoutEffect } from "@packages/common/src/custom-hooks/useAsync";
import RootPageLoader from "@packages/common/src/components/skeletons/RootPageLoader";

import HeadSection from "./HeadSection";
import authService from "../../../common/service/authService";
import { ALL_NOTEBOOKS_URL } from "../../utils/constants";

const Notebooks = dynamic(() => import("./Notebooks"), {
  loading: () => <RootPageLoader />,
});

const NotebooksListPage: React.FC<{ Component: any; pageProps: any }> = ({
  Component,
  pageProps,
}) => {
  const router = useRouter();

  useIsomorphicLayoutEffect(() => {
    if (!authService.isAuthenticated) {
      router.push(ALL_NOTEBOOKS_URL);
    }
  }, []);

  return (
    <>
      <HeadSection />
      <Notebooks Component={Component} pageProps={pageProps} />
    </>
  );
};

export default NotebooksListPage;
