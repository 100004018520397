import Head from "next/head";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";

import ErrorBoundary from "@packages/common/src/components/error-handlers/ErrorBoundary";

import theme from "./../src/common/config/theme";
import createEmotionCache from "./../src/common/config/createEmotionCache";
import Routes from "../src/common/components/routing/Routes";
import { SERVER } from "../src/common/config/utils";
import { SITE_NAME } from "../src/common/config/seo";

const AcceptCookies = dynamic(
  () => import("../src/accept-cookies/AcceptCookies")
);
const DialogProvider = dynamic(
  () =>
    import(
      "@packages/skillspace/src/common/components/dialog-provider/DialogProvider"
    )
);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{SITE_NAME} - Democratizing Data Science Learning</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="description"
          content="Learn Data Science for free through courses, practice on real-world datasets and discuss with leading domain experts and data science enthusiasts."
        />
        <meta property="og:type" content="website" key="type" />
        <meta property="og:site_name" content={SITE_NAME} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="twitter:domain" content={SERVER} />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter_card"
        />
        <link
          rel="shortcut icon"
          href="https://aiplanet.com/static/images/favicon.ico"
        />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <DialogProvider>
          <ErrorBoundary>
            <Routes Component={Component} pageProps={pageProps} />
          </ErrorBoundary>
        </DialogProvider>
        <AcceptCookies />
      </ThemeProvider>
    </CacheProvider>
  );
};

export default MyApp;
