import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useIsomorphicLayoutEffect } from "@packages/common/src/custom-hooks/useAsync";

import Section from "../../../common/components/layout/utils/Section";
import { dphiBlue } from "../../../common/config/colors";
import authService from "../../../common/service/authService";
import { FRONTEND_DASHBOARD_URL } from "../../../common/utils/constants";

const Footer = dynamic(
  () => import("../../../common/components/layout/footer/Footer")
);

const AccountsLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  useIsomorphicLayoutEffect(() => {
    if (
      authService.isAuthenticated &&
      !router.asPath.startsWith("/accounts/confirm-email")
    ) {
      // TODO: migrate to router.push once dashboard is migrated to nextjs
      window.location.href = FRONTEND_DASHBOARD_URL;
    }
  }, []);

  return authService.isAuthenticated &&
    !router.asPath.startsWith("/accounts/confirm-email") ? (
    <></>
  ) : (
    <>
      <Section boxProps={{ bgcolor: dphiBlue["A100"], py: { xs: 6, md: 12 } }}>
        <main>{children}</main>
      </Section>
      <Footer />
    </>
  );
};

export default AccountsLayout;
