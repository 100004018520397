import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { useIsomorphicLayoutEffect } from "@packages/common/src/custom-hooks/useAsync";

import authService from "../../../src/common/service/authService";
import { USER_LOGIN_URL } from "../../../src/common/utils/constants";

const MyProfilePageBase = dynamic(
  () => import("./common/layouts/MyProfilePageBase")
);
const Layout = dynamic(() => import("../../common/components/layout/Layout"));

const ProfilePage: React.FC<{ Component: any; pageProps: any }> = ({
  Component,
  pageProps,
}) => {
  const router = useRouter();

  useIsomorphicLayoutEffect(() => {
    if (
      !authService.isAuthenticated &&
      ["/profile/edit", "/profile/settings"].includes(router.asPath)
    ) {
      router.push(`${USER_LOGIN_URL}?next=${router.asPath}`);
      return;
    }
  }, []);

  return (
    <Layout>
      {router.asPath === "/profile/edit" ||
      router.asPath === "/profile/settings" ? (
        <MyProfilePageBase Component={Component} pageProps={pageProps} />
      ) : (
        <Component {...pageProps} />
      )}
    </Layout>
  );
};

export default ProfilePage;
