import React, { Component, ErrorInfo, ReactNode } from "react";
import GenericError from "./GenericError";

interface Props {
  children: ReactNode;
  email?: string;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

interface InitialState extends State {
  email: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: InitialState = {
    hasError: false,
    error: null,
    email: this.props.email || "support@aiplanet.com",
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <GenericError
          heading={"Something went wrong"}
          description={`Try reloading? If the problem persists please get in touch - ${this.props.email}`}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
