import { LinkTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const tabProps = (
  link: string,
  component: OverridableComponent<LinkTypeMap<{}, "a">>
) => {
  return {
    id: `simple-tab-${link}`,
    "aria-controls": `simple-tabpanel-${link}`,
    component: component,
    href: `#${link}`,
    underline: "none",
  };
};

/**
 * @param string which needs to be sliced
 * @param length
 * */
export const sliceString = (text: string, length: number) =>
  text.length > length ? `${text.slice(0, length)}...` : text;

/**
 * @param string
 * */
export default function toTitleCase(s: string) {
  if (s.length <= 0) return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Delete query in nextjs route
 * */
export const deleteQuery = (asPath: string) => {
  return asPath.split("?")[0];
};

/**
 * Get csrf cookie name
 * */
export const csrfCookieName = (() => {
  if (typeof window === "undefined") return "";
  // stage.aiplanet.com = aiplanetstagecsrftoken
  // aiplanet.com = aiplanetcsrftoken
  // else = aiplanetlocalcsrftoken
  const host = window.location.host;
  if (host === "stage.aiplanet.com") {
    return "aiplanetstagecsrftoken";
  } else if (host === "aiplanet.com") {
    return "aiplanetcsrftoken";
  } else {
    return "aiplanetlocalcsrftoken";
  }
})();

// check the environment
export const isProduction =
  process.env.NODE_ENV === "production" && process.env.STAGE === "0";
export const isStage =
  process.env.NODE_ENV === "production" && process.env.STAGE === "1";

/**
 * Get csrf cookie name on server
 * */
export const serverCsrfCookieName = (() => {
  // stage.aiplanet.com = aiplanetstagecsrftoken
  // aiplanet.com  = aiplanetcsrftoken
  // else = aiplanetlocalcsrftoken
  if (isStage) {
    return "aiplanetstagecsrftoken";
  } else if (isProduction) {
    return "aiplanetcsrftoken";
  } else {
    return "aiplanetlocalcsrftoken";
  }
})();

// scroll to section start
export const smoothScroll = (section: HTMLDivElement | null) => {
  section?.scrollIntoView({
    block: "start",
    behavior: "smooth",
  });
};

export const pushDataToGTM = (data: object) => {
  if (typeof window === "undefined") return;
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(data);
};
