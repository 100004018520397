import React from "react";
import Image from "next/image";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery } from "@mui/material";

import HeroSection from "../../../common/components/layout/hero-section/HeroSection";
import { neutral } from "../../../common/config/colors";
import { NOTEBOOKS_HEAD_SECTION } from "../../utils/constants";
import UploadNotebook from "./upload-notebook/UploadNotebook";

const HeadSection = () => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <HeroSection>
      <Grid
        container
        sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
      >
        <Grid item xs={12} md={8}>
          <Typography
            color={neutral["A500"]}
            variant="h1"
            fontSize={{ xs: 38, md: 48 }}
            fontWeight={700}
            sx={{
              textAlign: { xs: "center", md: "start" },
            }}
          >
            Notebooks
          </Typography>
          <Typography
            color={neutral["A500"]}
            fontSize={{ xs: 16, md: 18 }}
            fontWeight={500}
            sx={{
              textAlign: { xs: "center", md: "start" },
              py: 4,
            }}
          >
            Connecting ideas is where innovation comes from, and connecting with
            people makes those innovation come to life. Ideas aren&apos;t
            important to hold on to, their only importance is in sharing them
            with others.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", md: "flex-start" },
              pt: 4,
            }}
          >
            <UploadNotebook />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "grid",
              placeContent: "center",
              mb: { xs: 6, md: 0 },
            }}
          >
            <Image
              src={NOTEBOOKS_HEAD_SECTION}
              alt="notebooks"
              height={isDownSm ? 141 : 241}
              width={isDownSm ? 137 : 230}
            />
          </Box>
        </Grid>
      </Grid>
    </HeroSection>
  );
};

export default HeadSection;
