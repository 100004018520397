import dynamic from "next/dynamic";

const Header = dynamic(() => import("./header/Header"));
const Footer = dynamic(() => import("./footer/Footer"));
const NotificationBar = dynamic(
  () => import("../notification/NotificationBar")
);

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <NotificationBar />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
