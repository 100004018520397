import React, { useState, useEffect } from "react";
import { AxiosError } from "axios";

import UserContext from "./UserContext";
import authService from "../../service/authService";

const UserProvider: React.FC<IUserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IGetUserProps | null>(null);
  const [responseError, setResponseError] = useState<AxiosError | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded || authService.isAuthenticated) {
      const fetchUser = async () => {
        authService
          .user()
          .then((response) => {
            setUser(!!response.data.length ? response.data[0] : null);
            setIsLoaded(true);
          })
          .catch((error) => setResponseError(error));
      };
      fetchUser();
    }
  }, [isLoaded]);

  return (
    <UserContext.Provider
      value={{ user, responseError, isLoaded, setIsLoaded }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
