export const primary = {
  900: "#132A16",
  800: "#204624",
  700: "#2D6232",
  600: "#3A7E41",
  500: "#51AF5B",
  400: "#73BF7B",
  300: "#8FCC95",
  200: "#ABD8B0",
  100: "#C7E5CA",
  50: "#E3F2E5",
  A500: "#44924C",
  A50: "#F5FFF3",
};

export const secondary = {
  900: "#3D2A00",
  800: "#664600",
  700: "#8F6200",
  600: "#B87D00",
  500: "#E09900",
  400: "#FFB10A",
  300: "#FFC233",
  200: "#FFDA85",
  100: "#FFE7AD",
  50: "#FFF3D6",
  A100: "#FFCE5C",
};

export const neutral = {
  900: "#000000",
  800: "#222222",
  700: "#292929",
  600: "#333333",
  500: "#666666",
  400: "#858585",
  300: "#A3A3A3",
  200: "#CCCCCC",
  100: "#ECECEC",
  50: "#F5F5F5",
  A100: "#F9F9F9",
  A200: "#F6F8FA",
  A300: "#FAFAFA",
  A400: "#EDEDED",
  A500: "#ffffff",
  A600: "#F8F9FD",
  A700: "#F5F7F9",
  A800: "#EBEBED",
};

export const dphiBlue = {
  A100: "#003145",
  A200: "#002A3B",
  A300: "#082835",
  A400: "#002939",
};

export const bgColor = {
  50: "#F5F7F9",
  100: "#f0f1f2",
};
