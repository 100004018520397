import { useRouter } from "next/router";
import dynamic from "next/dynamic";

import NotebooksListPage from "./list/NotebooksListPage";
import {
  ALL_NOTEBOOKS_URL,
  FAVOURITE_NOTEBOOK_URL,
  MY_NOTEBOOKS_URL,
} from "../utils/constants";
const Layout = dynamic(() => import("../../common/components/layout/Layout"));

const NotebooksPage: React.FC<{ Component: any; pageProps: any }> = ({
  Component,
  pageProps,
}) => {
  const router = useRouter();

  return (
    <Layout>
      {router.pathname.startsWith(ALL_NOTEBOOKS_URL) ||
      router.pathname.startsWith(MY_NOTEBOOKS_URL) ||
      router.pathname.startsWith(FAVOURITE_NOTEBOOK_URL) ? (
        <NotebooksListPage Component={Component} pageProps={pageProps} />
      ) : (
        <Component {...pageProps} />
      )}
    </Layout>
  );
};

export default NotebooksPage;
