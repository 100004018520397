export const SERVER = process.env.NEXT_PUBLIC_SERVER;

export const LMS_SERVER = process.env.isProduction
  ? "https://learning.dphi.tech"
  : process.env.isStage
  ? "https://learning.stage.dphi.tech"
  : "http://local.overhang.io:8000";

export const STUDIO_SERVER = process.env.isProduction
  ? "https://studio.dphi.tech"
  : process.env.isStage
  ? "https://studio.stage.dphi.tech"
  : "http://local.overhang.io:8000";
