import { SERVER } from "../config/utils";

export const FRONTEND_COMMUNITY_URL = "/community/";
export const UPLOAD_ICON = "/static/images/notebooks/Uploadicon.png";

export const USER_LOGIN_URL = "/accounts/login/";
export const USER_SIGNUP_URL = "/accounts/signup/";
export const TAGS_API_URL = "/api/tags/";

export const HTTP_DEBOUNCE_TIME = 750;

// Challenges
export const SUBMISSION_CSV_MIME = "text/csv, application/vnd.ms-excel, .csv";

// frontend urls
export const FRONTEND_NOTEBOOKS_URL = "/notebooks";
export const FRONTEND_BOOTCAMPS_URL = "/bootcamps";
export const FRONTEND_CHALLENGES_URL = "/challenges";
export const FRONTEND_COURSES_URL = "/courses";
export const FRONTEND_LIVE_SESSIONS_URL = "/live-sessions";
export const FRONTEND_DASHBOARD_URL = "/dashboard";
export const FRONTEND_PROFILE_URL = "/profile";
export const FRONTEND_PROFILE_EDIT_URL = "/profile/edit";
export const FRONTEND_PROFILE_EDIT_EMAIL_URL = "/profile/edit/email";
export const FRONTEND_PROFILE_EDIT_PASSWORD_CHANGE_URL =
  "/profile/edit/social_accounts";
export const FRONTEND_PROFILE_EDIT_SOCIAL_ACCOUNTS =
  "/profile/edit/social_accounts";
export const FRONTEND_LOGIN_URL = "/accounts/login";
export const FRONTEND_SIGNUP_URL = "/accounts/signup";
export const FRONTEND_CONTRIBUTE_URL = "/contribute";
export const FRONTEND_WALL_OF_LOVE_URL = "/wall-of-love";
export const FRONTEND_LMS_URL = "/learn";
export const LMS_URL = "/lms";
export const FRONTEND_LMS_COURSES_URL = "/lms/admin/courses";
export const FRONTEND_LMS_CREATE_COURSES_URL = "/lms/admin/courses/create";
export const FRONTEND_LEARN_URL = "/learn";
export const FRONTEND_ABOUT_US_URL = "/about-us";
export const FRONTEND_LEARNING_PATHS_URL = "/learning-paths";

/* for logout url we need ending slash
because we are using django allauth and
it expects that ending slash */
export const FRONTEND_LOGOUT_URL = `${SERVER}/accounts/logout/`;

export const FRONTEND_DISCUSS_URL = "https://discuss.aiplanet.com/login";

// frontend business urls
export const FRONTEND_ASSESS_URL = "/business/assess/";
export const FRONTEND_BUSINESS_HOMEPAGE_URL = "/business/";

//change this url
export const FRONTEND_BUSINESS_URL = "/business/";

export const FRONTEND_BUSINESS_AUTH_URL = "/auth/business/";
// Candidate URLs
export const FRONTNED_CANDIDATE_URL = "/business/candidate/";

export const FRONTEND_COMMUNITY_HOMEPAGE_URL = "/community/";

export const FRONTEND_ECOSYSTEM_URL = "/ecosystem";

// Resource URLs

export const MATHJAX_JS =
  "//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_SVG";

export const USER_PROFILE_API_URL = "/user-profile/";
export const DISCORD_URL = "https://discord.gg/E2XfSEYm2W";
