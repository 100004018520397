import Axios, { AxiosResponse, AxiosRequestConfig, CancelToken } from "axios";
import { csrfCookieName } from "../utils/utils";
import { IBaseService } from "./types";
import { getCookie } from "./utils";

/**
 * Base service for all DPhi API services.
 */
export default class BaseService implements IBaseService {
  baseUrl: string;

  constructor() {
    this.baseUrl = process.env.NEXT_PUBLIC_SERVER as string;
  }

  getAxiosConfig(): AxiosRequestConfig {
    return {
      headers: {
        "X-CSRFToken": getCookie(csrfCookieName) as string,
      },
      withCredentials: true,
    };
  }

  buildUrl(path: string) {
    return `${this.baseUrl}/api${path}`;
  }

  async get(
    url: string,
    params?: object,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<any>> {
    const response = await Axios.get(url, {
      method: "GET",
      params,
      cancelToken,
      ...this.getAxiosConfig(),
    });
    return response;
  }

  async post(
    url: string,
    body: object,
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    const response = await Axios.post(url, body, {
      ...this.getAxiosConfig(),
      ...axiosConfig,
    });
    return response;
  }

  async put(
    url: string,
    body: object,
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    const response = await Axios.put(url, body, {
      ...this.getAxiosConfig(),
      ...axiosConfig,
    });
    return response;
  }

  async patch(
    url: string,
    body: object,
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    const response = Axios.patch(url, body, {
      ...this.getAxiosConfig(),
      ...axiosConfig,
    });
    return response;
  }

  async delete(url: string, body?: object): Promise<AxiosResponse<any>> {
    const response = await Axios.delete(url, {
      data: { ...(body || {}) },
      ...this.getAxiosConfig(),
    });
    return response;
  }
}
