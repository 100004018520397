import React from "react";
import dynamic from "next/dynamic";
import Box from "@mui/material/Box";

import { useTheme } from "@mui/material";

import useUserContext from "../../../../common/context/get-user/useUserContext";

const ModalWithStyles = dynamic(
  () => import("@packages/common/src/components/modal/ModalWithStyles")
);
const UsernameForm = dynamic(() => import("./UsernameForm"));

const UpdateUsername = () => {
  const theme = useTheme();
  const { user } = useUserContext();

  return (
    <>
      {user &&
        (user?.needs_username_update ? (
          <ModalWithStyles modalProps={{ open: true }}>
            <Box
              sx={{
                width: 508,
                borderRadius: 4,
                [theme.breakpoints.down("sm")]: { width: "90%" },
              }}
            >
              <UsernameForm />
            </Box>
          </ModalWithStyles>
        ) : (
          <></>
        ))}
    </>
  );
};

export default UpdateUsername;
