import { USER_PROFILE_API_URL } from "../utils/constants";
import BaseService from "./baseService";
import { getCookie } from "./utils";

/**
 * A singleton service for authentication.
 */
class AuthService extends BaseService {
  /**
   * True if user is logged in.
   */
  get isAuthenticated(): Boolean {
    return Boolean(parseInt(getCookie("auth") || "0"));
  }

  /**
   * Return the current user
   */
  async user() {
    return await this.get(this.buildUrl(USER_PROFILE_API_URL), { my: 1 });
  }
}

const authService = new AuthService();
Object.freeze(authService);

export default authService;
