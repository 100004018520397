import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { dphiBlue } from "../../../config/colors";
import Section from "../utils/Section";
import { LANDING_PAGE } from "./utils";
import { NSLayout } from "../types";

const HeroSection: React.FC<NSLayout.ISectionProps> = ({
  boxProps,
  ...props
}) => {
  return (
    <Box
      sx={{
        background: dphiBlue["A100"],
      }}
    >
      <Paper
        sx={{
          backgroundColor: "transparent",
          backgroundImage: { sm: "none", md: `url(${LANDING_PAGE})` },
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Section
          boxProps={{ py: { xs: 12, md: 24 }, ...(boxProps || {}) }}
          {...props}
        />
      </Paper>
    </Box>
  );
};

export default HeroSection;
