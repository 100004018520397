import React from "react";
import { useRouter } from "next/router";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const SearchApps = () => {
  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      search: (router.query?.q as string) || "",
    },
    onSubmit: (values: { search: string }) => {
      if (!!values?.search) {
        router.push({
          pathname: "/apps/search",
          query: { search: values.search, page: router.query?.page || 1 },
        });
      } else {
        router.push("/apps");
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="search"
        value={formik.values.search}
        onChange={formik.handleChange}
        placeholder="Search Apps"
        type="search"
        size="small"
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          bgcolor: "#E9E9EB",
          borderRadius: 2.5,
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
          },
          "& .Mui-focused": {
            borderWidth: 1,
            borderRadius: 2.5,
          },
          "& .MuiInputAdornment-root": {
            color: "text.primary",
          },
        }}
      />
    </form>
  );
};

export default SearchApps;
