import React from "react";
import dynamic from "next/dynamic";
import Header from "./common/Header";

const Footer = dynamic(
  () => import("../../common/components/layout/footer/Footer")
);

const AppsPage: React.FC<{ Component: any; pageProps: any }> = ({
  Component,
  pageProps,
}) => {
  return (
    <>
      <Header />
      <Component {...pageProps} />
      <Footer />
    </>
  );
};

export default AppsPage;
