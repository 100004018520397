export const NOTEBOOKS_URL = "/api/notebooks/";
export const NOTEBOOK_TAGS_URL = "/notebooks/tags/";

// Static resources
export const NOTEBOOKS_HEAD_SECTION = "/notebooks/notebooks-head-section.svg";
export const EMPTY_NOTEBOOKS = "/notebooks/empty-notebooks.png";
export const SERVER_ERROR_IMAGE = undefined;
export const UPLOAD_NOTEBOOK_IMAGE = "/notebooks/upload-icon.png";

// Misc
export const HTTP_DEBOUNCE_TIME = 500;
export const MIN_NB_TITLE_LENGTH = 5;
export const MIN_NB_SUMMARY_LENGTH = 10;

export const ALL_NOTEBOOKS_URL = "/notebooks/all-notebooks";
export const MY_NOTEBOOKS_URL = "/notebooks/my-notebooks";
export const FAVOURITE_NOTEBOOK_URL = "/notebooks/bookmarks";
