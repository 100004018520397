import React from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

import Route from "./Route";
import AccountsLayout from "../../../accounts/components/common/AccountsLayout";
import UserProvider from "../../context/get-user/UserProvider";
import {
  FRONTEND_LEARN_URL,
  FRONTEND_NOTEBOOKS_URL,
  LMS_URL,
} from "../../utils/constants";
import NotebooksPage from "../../../notebooks/components/NotebooksPage";
import UpdateUsername from "../../../accounts/components/signup/social/UpdateUsername";
import Layout from "../layout/Layout";
import ProfilePage from "../../../profile/components/ProfilePage";
import AppsPage from "../../../apps/components/AppsPage";

const LearnPage = dynamic(() => import("../../../learn/components/LearnPage"));
const LmsAdminPage = dynamic(
  () => import("../../../lms/admin/components/LmsAdminPage")
);
const OrganizerPage = dynamic(
  () => import("../../../organizer/common/components/Layout/OrganizerPage")
);

const DashboardPage = dynamic(() => import("../../../dashboard/DashboardPage"));

/**
 * TODO: Fix conditional routing with nextjs version 13
 * */
const Routes: React.FC<{ Component: any; pageProps: any }> = ({
  Component,
  pageProps,
}) => {
  const router = useRouter();

  return (
    <>
      {/* order of the Route component is important */}
      <Route path="/accounts">
        <AccountsLayout>
          <Component {...pageProps} />
        </AccountsLayout>
      </Route>
      <UserProvider>
        <>
          <UpdateUsername />
          <Route path="/learning-paths">
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </Route>
          <Route path="/profile">
            <ProfilePage Component={Component} pageProps={pageProps} />
          </Route>
          <Route path={FRONTEND_LEARN_URL}>
            <LearnPage Component={Component} pageProps={pageProps} />
          </Route>
          <Route path={LMS_URL}>
            <LmsAdminPage Component={Component} pageProps={pageProps} />
          </Route>
          <Route path={FRONTEND_NOTEBOOKS_URL}>
            <NotebooksPage Component={Component} pageProps={pageProps} />
          </Route>
          <Route path="/organizer">
            <OrganizerPage Component={Component} pageProps={pageProps} />
          </Route>
          <Route path="/dashboard">
            <DashboardPage Component={Component} pageProps={pageProps} />
          </Route>
          <Route path="/apps" pageProps={pageProps}>
            <AppsPage Component={Component} pageProps={pageProps} />
          </Route>
          {/* Check if route is already rendered or not */}
          {![
            "/accounts",
            "/learning-paths",
            "/profile",
            "/dashboard",
            "/learn",
            "/lms",
            "/notebooks",
            "/organizer",
            "/apps",
          ].find((path) => router.asPath.startsWith(path)) && (
            <Route path="/" isBaseRoute>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </Route>
          )}
        </>
      </UserProvider>
    </>
  );
};

export default Routes;
