import React, { useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Button from "@mui/material/Button";

import authService from "../../../../common/service/authService";
import { USER_LOGIN_URL } from "../../../../common/utils/constants";

const NotebookModal = dynamic(() => import("./NotebookModal"));

const UploadNotebook = () => {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const handleNotebookModal = () => {
    if (authService.isAuthenticated) {
      setOpen(true);
    } else {
      router.push(`${USER_LOGIN_URL}?next=${router.asPath}`);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontSize: 14,
          fontWeight: 600,
          py: 3,
        }}
        onClick={handleNotebookModal}
      >
        Upload Notebook
      </Button>
      <NotebookModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default UploadNotebook;
